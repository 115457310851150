.app-wrapper {
    display: flex;
    flex-direction: row;
}

.left {
    flex-grow: 1;
    max-width: calc(100% - 625px);
    height: 100vh;
    background: #c8d8ef;
    /*background-image: url('bottom_bg.png');*/
    padding: 44px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.logo {
    height: 32px;
    width: auto;
}

.header {
    width: 400px;
    height: auto;
}

.text {
    padding-top: 25px;
    width: 400px;
    height: auto;
}

.cal-wrapper {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 625px;
    /*overflow: hidden;*/
}

@media (max-width: 768px) {
    .app-wrapper {
        flex-direction: column;
    }

    .left {
        max-width: 100%;
        height: 50px;
        position: sticky;
        z-index: 0;
        padding-top: 22px;
        padding-left: 38px;
    }

    .logo {
        height: 22px;
    }

    .cal-wrapper {
        z-index: 1;
        flex: 0 0 100%;
        width: 100% !important;
        height: calc(100vh - 68px);
        /*overflow: hidden;*/
    }
}
